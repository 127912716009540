import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

var firebaseConfig = {
  apiKey: "AIzaSyDs7b8fpN5Suidf9LOpg_PqIQKRugrW31Q",
  authDomain: "singernus.firebaseapp.com",
  projectId: "singernus",
  storageBucket: "singernus.appspot.com",
  messagingSenderId: "301570635178",
  appId: "1:301570635178:web:b1647cb73c4be2938667d3",
  measurementId: "G-D47K5FXHK3",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

export { db, app, auth };
